import LandingPage from '../containers/landing-page';
import Peers from '../containers/peer-listing';
import Checkins from '../containers/check-ins';
import Requests from '../containers/requests';
import Profile from '../containers/peer-profiles/profile';
import Engagement from '../containers/peer-profiles/engagement';
import Connections from '../containers/peer-profiles/connections';
import Additional from '../containers/peer-profiles/Additional';
import Log from '../containers/peer-profiles/log';
import OnCall from '../containers/peer-profiles/on-call';
import MembersProfile from '../containers/members/profile';
import Members from '../containers/members';
import MemberConnections from '../containers/members/connections';
import MembersRewards from '../containers/members/rewards';
import Comms from '../containers/members/comms';
import memberLog from '../containers/members/log';
import MemberEngagements from '../containers/members/engagement';
import Calls from '../containers/connections';
import Stories from '../containers/stories';
import Topics from '../containers/topics';
import Clients from '../containers/clients';
import Packages from '../containers/clients/packages';
import Configure from '../containers/clients/configure';
import ClientResources from '../containers/clients/resources';
import Demographics from '../containers/client-configuration/engagement-details';
import EligibilityFile from '../containers/client-configuration/eligibilty-file';
import ClientsLanding from '../containers/client-configuration/index';
import RichTextEditor from '../components/common/rich-text-editor';
import ClientEligibility from '../containers/client-configuration/eligibility';
import Reporting from '../containers/clients/reporting';
import Campaigns from '../containers/templates/campaigns';
import TemplateComms from '../containers/templates/comms';
import Admins from '../containers/clients/admins';
import MemberTOS from '../containers/clients/text-blocks/members';
import ClientAdminTOS from '../containers/clients/text-blocks/client-adminstrator';
import Tiers from '../containers/listener-tiers';
import TiersDetails from '../containers/listener-tiers/tier-details';
import Tags from '../containers/tags-management';
import TagsDetails from '../containers/tags-management/tag-details';
import TagGroups from '../containers/tags-management/tag-groups';
import TagGroupsDetails from '../containers/tags-management/tag-groups/tag-group-details';
import ListenerFaqTOS from '../containers/clients/text-blocks/listeners';
import { UserRole } from '../containers/navigation-bar/roles';
import Audio from '../containers/peer-profiles/audio-approval';
import Payout from '../containers/peer-profiles/payout';
import Resources from '../containers/resources';
import ResourceDetails from '../containers/resources/resource-details';
import Messages from '../containers/listener-reviewers/audio-approval';
import ProfileReview from '../containers/listener-reviewers/profile-review';
import DisplayNameReview from '../containers/listener-reviewers/display-name-review';
import AboutMeReview from '../containers/listener-reviewers/about-me-review';
import BackgroundTagApproval from '../containers/listener-reviewers/background-tag-approval';
import GlobalDashboardPeers from '../containers/landing-page/global-dashboard-peers';
import GlobalDashboardTopics from '../containers/landing-page/global-dashboard-topics';
import GlobalDashboardNavigation from '../containers/landing-page/global-dashboard-navigation';
import blacklist from '../containers/user-safety/blacklist';
import CallerBlockList from '../containers/user-safety/caller-block-list';
import UserManagement from '../containers/user-safety/user-managment';
import Settings from '../containers/user-safety/settings';
import Files from '../containers/files';
import Users from '../containers/users';
import { SearchQueriesPage, SearchQueriesTestPage } from '../containers/search-queries/pages';
import PlusCarePage from '../containers/plus-care/plus-care';
import { SSOConfiguration } from '../containers/clients/sso-configuration';
import { LinksPage } from '../containers/links/links';

export const ROUTE_PATH = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  DASHBOARD_OVERVIEW: '/dashboard/overview',
  DASHBOARD_ENGAGEMENT: '/dashboard/engagement',
  DASHBOARD_TOPICS: '/dashboard/topics',
  DASHBOARD_PEERS: '/dashboard/peers',
  DASHBOARD_NAVIGATION: '/dashboard/navigation',
  LISTENERS: '/listeners',
  SEARCH_QUERIES: '/search-queries',
  SEARCH_QUERIES_TEST: '/search-queries/test',
  LINKS: '/links',
  PLUS_CARE: '/+care',
  PEERS: '/peers',
  PEER_LISTENERS: '/peer-listeners',
  REVIEW: '/review',
  CHECKINS: '/checkins',
  REQUESTS: '/requests',
  DEMOGRAPHICS: '/review',
  PROJECT_MANAGEMENT: '/project-management',
  SAFETY: '/safety',
  LISTENERS_PROFILE: '/listeners/profile',
  LISTENERS_ENGAGEMENT: '/listeners/engagement',
  LISTENERS_CONNECTION: '/listeners/connection',
  LISTENERS_AVAILABLE: '/listeners/availabilty',
  LISTENER_AUDIO_APPROVAL: '/listeners/audio',
  LISTENER_PAYOUT: '/listeners/payout',
  LISTENERS_LOG: '/listeners/log',
  LISTENERS_ON_CALL: '/listeners/oncall',

  PEERS_PROFILE: '/peers/profile',
  PEERS_ENGAGEMENT: '/peers/engagement',
  PEERS_CONNECTION: '/peers/connection',
  PEER_AUDIO_APPROVAL: '/peers/audio',
  PEER_PAYOUT: '/peers/payout',

  MEMBERS: '/members',
  MEMBERS_PROFILE: '/members/profile',
  MEMBERS_CONNECTIONS: '/members/connection',
  MEMBERS_REWARDS: '/members/rewards',
  MEMBERS_COMMS: '/members/comms',
  MEMBERS_LOG: '/members/log',
  MEMBERS_ENGAGEMENT: '/members/engagement',
  SETTINGS: '/settings',
  STORIES: '/stories',
  TOPICS: '/topics',
  GROUPS: '/groups',
  GROUPS_PACKAGES: '/groups/packages',
  GROUPS_CONFIGURE: '/groups/configure',
  GROUPS_ELIGIBILITY: '/groups/eligibility',
  GROUPS_RESOURCES: '/groups/resources',
  GROUPS_ADMINS: '/groups/admins',
  GROUPS_SSO_CONFIGURATION: '/groups/sso-configuration',
  REPORTING: '/groups/reporting',
  TEMPLATES_COMMS: '/templates/comms',
  TEMPLATES_CAMPAIGNS: '/templates/campaigns',
  NOTIFY_MESSAGE: '/notify-message',
  ONBOARDING: '/onboarding',
  REQUESTNEWINVITE: '/request-new-invite',
  INVITEREVOKE: '/invite-revoked',
  MEMBERSTOC: '/text-block/members',
  CLIENTADMINTOC: '/text-block/client-admin',
  LISTENRFAQTOC: '/text-block/listener-faq',
  INVITATION_COMPLETED: '/onboarding-completed',
  TIERS: '/tiers',
  TIER_DETAILS: '/tier-details',
  TAGS: '/tags',
  TAGS_DETAILS: '/tags-details',
  TAG_GROUPS: '/tag-groups',
  TAG_GROUPS_DETAILS: '/tag-groups-details',
  RESOURCES: '/resources',
  RESOURCES_DETAILS: '/resources-details',
  MESSAGES: '/listeners-reviews/messages',
  PROFILE: '/listeners-reviews/profiles',
  DISPLAY_NAME: '/listeners-reviews/display-name',
  ABOUT_ME: '/listeners-reviews/about-me',
  BACKGROUND_TAGS: '/listeners-reviews/background-tags',
  FILES: '/files',
  CALLS: '/calls',
  SYSTEM_USERS: '/users',

  //clients
  SNAPSHOT: '/snapshot',
  ENAGEMENT: '/engagement',
  ELIGIBILTY: '/eligibility',
  GROUPMANAGER: '/group-manager',
  MEMBER_RESOURCES: '/member-resources',
  MARKETING_LIBRARY: '/marketing-library',
  USER_MANAGEMENT: '/user-safety/user-management',
  BLACKLIST: '/user-safety/blacklist',
  BLOCKED: '/user-safety/blocked',
};

export const AdminRoutes = [
  {
    path: ROUTE_PATH.DASHBOARD,
    component: LandingPage,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.SEARCH_QUERIES,
    component: SearchQueriesPage,
    role: UserRole.admin,
    id: 'SearchQueries',
  },
  {
    path: ROUTE_PATH.SEARCH_QUERIES_TEST,
    component: SearchQueriesTestPage,
    role: UserRole.admin,
    id: 'SearchQueries',
  },
  {
    path: ROUTE_PATH.LINKS,
    component: LinksPage,
    role: UserRole.admin,
    id: 'Links',
  },
  {
    path: ROUTE_PATH.PLUS_CARE,
    component: PlusCarePage,
    role: UserRole.admin,
    id: 'PlusCare',
  },
  {
    path: ROUTE_PATH.DASHBOARD_OVERVIEW,
    component: LandingPage,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ENGAGEMENT,
    component: LandingPage,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_TOPICS,
    component: GlobalDashboardTopics,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_PEERS,
    component: GlobalDashboardPeers,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_NAVIGATION,
    component: GlobalDashboardNavigation,
    role: UserRole.admin,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.PEERS,
    component: Peers,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_LISTENERS,
    component: Peers,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.TEMPLATES_CAMPAIGNS,
    component: Campaigns,
    role: UserRole.admin,
    id: 'Templates',
  },
  {
    path: ROUTE_PATH.TEMPLATES_COMMS,
    component: TemplateComms,
    role: UserRole.admin,
    id: 'Templates',
  },
  {
    path: ROUTE_PATH.CHECKINS,
    component: Checkins,
    role: UserRole.admin,
    id: 'Check-Ins',
  },
  {
    path: ROUTE_PATH.REQUESTS,
    component: Requests,
    role: UserRole.admin,
    id: 'Requests',
  },
  {
    path: ROUTE_PATH.DEMOGRAPHICS,
    component: Demographics,
    role: UserRole.admin,
    id: 'Demographics',
  },
  {
    path: ROUTE_PATH.PROJECT_MANAGEMENT,
    component: RichTextEditor,
    role: UserRole.admin,
    id: 16,
  },
  {
    path: ROUTE_PATH.BLACKLIST,
    component: blacklist,
    role: UserRole.admin,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.BLOCKED,
    component: CallerBlockList,
    role: UserRole.admin,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.USER_MANAGEMENT,
    component: UserManagement,
    role: UserRole.admin,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.LISTENERS_PROFILE,
    component: Profile,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEERS_PROFILE,
    component: Profile,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_ENGAGEMENT,
    component: Engagement,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEERS_ENGAGEMENT,
    component: Engagement,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_CONNECTION,
    component: Connections,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_AVAILABLE,
    component: Additional,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENER_AUDIO_APPROVAL,
    component: Audio,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_AUDIO_APPROVAL,
    component: Audio,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_PAYOUT,
    component: Payout,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENER_PAYOUT,
    component: Payout,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_LOG,
    component: Log,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_ON_CALL,
    component: OnCall,
    role: UserRole.admin,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.MEMBERS,
    component: Members,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_PROFILE,
    component: MembersProfile,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_CONNECTIONS,
    component: MemberConnections,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_REWARDS,
    component: MembersRewards,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_COMMS,
    component: Comms,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_LOG,
    component: memberLog,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_ENGAGEMENT,
    component: MemberEngagements,
    role: UserRole.admin,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.SETTINGS,
    component: Settings,
    role: UserRole.admin,
    id: 'Settings',
  },
  {
    path: ROUTE_PATH.CALLS,
    component: Calls,
    role: UserRole.admin,
    id: 'Calls',
  },
  {
    path: ROUTE_PATH.STORIES,
    component: Stories,
    role: UserRole.admin,
    id: 'Stories',
  },
  {
    path: ROUTE_PATH.TOPICS,
    component: Topics,
    role: UserRole.admin,
    id: 'Topics',
  },
  {
    path: ROUTE_PATH.GROUPS,
    component: Clients,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_PACKAGES,
    component: Packages,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_CONFIGURE,
    component: Configure,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_ELIGIBILITY,
    component: EligibilityFile,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_RESOURCES,
    component: ClientResources,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_ADMINS,
    component: Admins,
    role: UserRole.admin,
    id: 1,
  },
  {
    path: ROUTE_PATH.GROUPS_SSO_CONFIGURATION,
    component: SSOConfiguration,
    role: UserRole.admin,
    id: 'Groups-SSO-Configuration',
  },
  {
    path: ROUTE_PATH.REPORTING,
    component: Reporting,
    role: UserRole.admin,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.STORIES,
    component: Stories,
    role: UserRole.admin,
    id: 'Stories',
  },
  {
    path: ROUTE_PATH.TIERS,
    component: Tiers,
    role: UserRole.admin,
    id: 'Tiers',
  },
  {
    path: ROUTE_PATH.TIER_DETAILS,
    component: TiersDetails,
    role: UserRole.admin,
    id: 'Tiers',
  },
  {
    path: ROUTE_PATH.TAGS,
    component: Tags,
    role: UserRole.admin,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.TAGS_DETAILS,
    component: TagsDetails,
    role: UserRole.admin,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.TAG_GROUPS,
    component: TagGroups,
    role: UserRole.admin,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.TAG_GROUPS_DETAILS,
    component: TagGroupsDetails,
    role: UserRole.admin,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.RESOURCES,
    component: Resources,
    role: UserRole.admin,
    id: 'Resources',
  },
  {
    path: ROUTE_PATH.SYSTEM_USERS,
    component: Users,
    role: UserRole.admin,
    id: 'System Users',
  },
  {
    path: ROUTE_PATH.RESOURCES_DETAILS,
    component: ResourceDetails,
    role: UserRole.admin,
    id: 'Resources',
  },
  //clients
  {
    path: ROUTE_PATH.SNAPSHOT,
    component: ClientsLanding,
    role: UserRole.client_admin,
    id: 'Snapshot',
  },
  {
    path: ROUTE_PATH.ELIGIBILTY,
    component: EligibilityFile,
    role: UserRole.client_admin,
    id: 'Eligibility',
  },
  {
    path: ROUTE_PATH.GROUPMANAGER,
    component: ClientEligibility,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MEMBER_RESOURCES,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MARKETING_LIBRARY,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MEMBERSTOC,
    component: MemberTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.CLIENTADMINTOC,
    component: ClientAdminTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.LISTENRFAQTOC,
    component: ListenerFaqTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.MESSAGES,
    component: Messages,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.PROFILE,
    component: ProfileReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.DISPLAY_NAME,
    component: DisplayNameReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.ABOUT_ME,
    component: AboutMeReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.BACKGROUND_TAGS,
    component: BackgroundTagApproval,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.FILES,
    component: Files,
    role: UserRole.admin,
    id: 'Files',
  },
];

export const clientRoutes = [
  {
    path: ROUTE_PATH.SNAPSHOT,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Snapshot',
  },
  {
    path: ROUTE_PATH.ELIGIBILTY,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Eligibility',
  },
  {
    path: ROUTE_PATH.GROUPMANAGER,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MEMBER_RESOURCES,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MARKETING_LIBRARY,
    component: Stories,
    role: UserRole.client_admin,
    id: 'Group Manager',
  },
];

export const getRoutes = () => {
  const roleType = localStorage.getItem('role_type');

  if (roleType === UserRole.admin) {
    return AdminRoutes;
  } else if (roleType === UserRole.client_admin) {
    return clientRoutes;
  } else {
    return AdminRoutes;
  }
};
